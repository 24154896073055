import React, { Component } from 'react'

import '../css/Intro.scss'

export default class Intro extends Component {
  render() {
    const { introContent } = this.props
    return (
      <div className="research-intro">
        {introContent}
        {/* <br /> */}
        <p>
          <b>
            We welcome creative, collaborative, and cool people to join the
            team!
          </b>{' '}
          If you are looking for post-docs or visiting research positions,
          please contact Haijun via email directly. For undergraduate interns,{' '}
          <a
            href="https://forms.gle/wEg12NrduEJpDWEo9"
            target="_blank"
            rel="noopnener noreferrer"
          >
            apply here
          </a>
          !
        </p>
        <div className="tags">
          <p>
            <span className="new-tag">NEW</span>
            <a href="/foundation">
              Talk: Redesigning the Information Space to Unleash the Power of AI
            </a>
          </p>
          <p>
            <span className="new-tag">NEW</span>
            <a href="/ai">Latest human-AI interaction research.</a>
          </p>
        </div>
      </div>
    )
  }
}
