import React, { useEffect, useRef } from 'react'

import Footer from '../components/Footer'
import Header from '../components/Header'
import { useScript } from '../components/useScript'
import { copyToClipboard } from '../components/utils'
import { HeadHelmet } from '../components/Helmet'

import Lab from '../assets/others/lab.png'

import '../css/Contact.scss'

const Contact = () => {
  const mapContainer = useRef(null)
  const emailMessage = useRef(null)

  const status = useScript(
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyCy9muXu2uMQVpG9SiaI2-PFEG0WAWu_JU'
  )
  useEffect(() => {
    if (status === 'ready') {
      initMap(mapContainer.current)
    }
  })

  return (
    <>
      <HeadHelmet
        title={'Contact | Creativity Lab'}
        description={'Creativity Lab at UC San Diego'}
        url={'contact'}
        image={Lab}
      />
      <Header highlighted="contact" />
      <div ref={mapContainer} className="map-container"></div>
      <div className="contact-info">
        <p>
          The Creativity Lab is part of the{' '}
          <a
            href="https://cogsci.ucsd.edu/"
            target="_blank"
            rel="noopenner noreferrer"
          >
            Cognitive Science Department
          </a>{' '}
          and the{' '}
          <a
            href="https://designlab.ucsd.edu/"
            target="_blank"
            rel="noopenner noreferrer"
          >
            Design Lab
          </a>{' '}
          at University of California San Diego. We are located in the freshly
          built{' '}
          <a
            href="https://ucsdnews.ucsd.edu/feature/uc-san-diego-opens-the-design-and-innovation-building"
            target="_blank"
            rel="noopenner noreferrer"
          >
            Design and Innovation Building
          </a>
          , minutes away from the beaches.
        </p>
        <ul>
          <li>
            9500 Gilman Dr, Design {'&'} Innovation Building
            <br />
            La Jolla, CA 92093, Room 340 (
            <a
              href="https://goo.gl/maps/ijfMvRybxezw4a8GA"
              target="_blank"
              rel="noopenner noreferrer"
            >
              map
            </a>
            )
          </li>
          <li>
            <p
              onClick={() => {
                copyToClipboard('creativity@ucsd.edu', emailMessage.current)
              }}
            >
              <span ref={emailMessage} className="copy-message">
                click to copy
              </span>
              creativity@ucsd.edu
            </p>
          </li>
        </ul>
      </div>
      <Footer />
    </>
  )
}

export default Contact

function initMap(component) {
  const designLab = { lat: 32.88008, lng: -117.232934 }
  const map = new window.google.maps.Map(component, {
    zoom: 17,
    center: designLab,
    disableDefaultUI: true,
    styles: [
      {
        elementType: 'geometry',
        stylers: [
          {
            color: '#f5f5f5',
          },
        ],
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#f5f5f5',
          },
        ],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#bdbdbd',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            color: '#ffffff',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: '#dadada',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5',
          },
        ],
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#c9c9c9',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
    ],
  })
  new window.google.maps.Marker({
    position: designLab,
    map: map,
  })
}
